import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Button, Col, Icon, Row} from 'antd';
import {DefaultLayout} from "../../layouts";
import {Container} from '../../components';
import data from "../../data";
import YouTube from "react-youtube";

class HomePage extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
    }

    loadData() {
        this.setState({loading: true});
        data.allSettings()
            .then(settings => {
                const transformedSettings = settings.reduce((result, current) => {
                    result[current.key] = current.value;
                    return result;
                }, {});
                this.setState({settings: transformedSettings});
            })
            .catch(err => console.log('Error getting settings.', err))
            .finally(() => this.setState({loading: false}));
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <DefaultLayout>
                <Container color="dark">
                    <div className="section" style={{color: 'white', paddingTop: 32, paddingBottom: 32}}>
                        <h1 style={{color: 'white'}}>TÜRKÇE VE TÜRK KÜLTÜRÜ DERSİNE BAŞVURMANIN EN KOLAY YOLU</h1>
                        <p>T.C. Stuttgart Başkonsolosluğu Eğitim  Ataşeliği olarak, Türkçe Anadil dersini(TTK), Eyalet Eğitim Bakanlığı ile işbirliği halinde eyalet kamu okullarında sunmaktayız.</p>
                        <p>Baden-Württemberg eyaletinin Württemberg Bölgesindeki Türkçe ve Türk Kültürü derslerine başvuru için hazırlamış olduğumuz sayfaya hoş geldiniz. TTK(Türkçe ve Türk Kültürü) dersine başvuru sürecini kolaylaştırmak ve hızlandırmak için sade, anlaşılır bir tasarımla karşınızdayız.</p>
                    </div>
                </Container>
                <Container color="light">
                    <div className="section">
                        {this.state.loading && (
                            <React.Fragment>
                                <Icon spin type="sync" style={{marginRight: 5}}/>
                                Lütfen bekleyin...
                            </React.Fragment>
                        )}
                        {this.state.settings?.REGISTRATION_VIDEO && (
                            <YouTube
                                videoId={this.state.settings?.REGISTRATION_VIDEO}
                                opts={{width: '100%'}}
                            />
                        )}
                    </div>
                </Container>
                <Container>
                    <div className="section">
                        <Row gutter={[32,32]}>
                            <Col md={12}>
                                <h1 className="section-header">Öğrenci Kayıt</h1>
                                <p>Türkçe ve Türk Kültürü dersimiz, okulların eğitim-öğretime açılması ile başlamaktadır. Veli başvurusuna bağlı olarak açılan dersimize, çocuğunuzun katılımı için başvuru formunu doldurmanız gerekmektedir. Hazırladığımız bu sayfa ile başvurunuz hem Ataşeliğimize hem de çocuğunuzun eğitim gördüğü okulun e-postasına ulaşacaktır.</p>
                                <p>Kayıt için aşağıdaki işlem sırasını takip ediniz.</p>
                                <ul>
                                    <li>Öğrenci Ders Kaydı butonuna tıklayın.</li>
                                    <li>Açılan sayfada ‘Yeni Hesap Aç’ butonuna tıklayın.</li>
                                    <li>Hesabınızı etkinleştirdikten sonra sizden istenen bilgileri girin.</li>
                                    <li>Onay işleminin ardından gelen ekrandan başvuru formunu indirebilirsiniz.</li>
                                    <li>Formu imzaladıktan sonra okul sekretaryasına teslim edebilirsiniz.</li>
                                </ul>
                                <br/>
                                <Link to="/register">
                                    <Button type="primary">Öğrenci ders kaydı</Button>
                                </Link>
                            </Col>
                            <Col md={12}>
                                <h1 className="section-header">Ders Planları</h1>
                                <p>Türkçe ve Türk Kültürü dersi Öğretim Programı, Milli Eğitim Bakanlığı tarafından kurulan komisyon marifetiyle hazırlanmıştır.</p>
                                <p>Günümüz dünyasında birden çok dile sahip olma olağan bir durum hâline gelmiştir. Yurt dışında yaşayan Türk çocukları, Türkçenin yanı sıra, yaşadıkları ülkenin dili ve eğitim sürecinde kazandıkları en az bir yabancı dille birlikte çoğul dilli hâle gelmektedir. Yurt dışında ana diline maruz kalma süreçleri farklılaşan Türk çocuklarına, Türkçelerini geliştirme ve Türk kültürünü etkin bir biçimde tanıma imkânı sağlanması büyük önem taşımaktadır. Çünkü yurt dışındaki Türk çocuklarının, Türkiye ve Türk kültürüyle bağlarını en yüksek düzeyde Türkçe oluşturmaktadır. Bu sebeple Türkçe, Türkçe ve Türk Kültürü Öğretim Programı’nın merkezine alınmış; dinleme, konuşma, okuma ve yazma temel beceri alanlarında kazanımlar oluşturulmuştur.</p>
                                <br/>
                                <Link to="/plans">
                                    <Button type="primary" onClick={this.openWhyTurkishModal}>Ders planlarını göster</Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default HomePage;
