import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Icon, Layout, Menu, notification} from 'antd';
import {Container} from "../components";
import {Authentication, ShowErrorNotification} from '../utils';

class DefaultLayout extends React.Component {
    state = {};

    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    logout() {
        Authentication.logout()
            .then(() => {
                notification.success({
                    message: 'Kullanıcı çıkışı yapıldı',
                    description: 'Tekrar giriş yapabilir veya bu sayfayı kapatabilirsiniz.'
                });
                setTimeout(() => {
                    this.props.history.push('/login');
                }, 100);
            })
            .catch(err => ShowErrorNotification('Kullanıcı çıkışı yapılamadı', err))
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        Authentication.getCurrentUser()
            .then(user => this.setState({user}))
            .catch(err => {
                if (err !== 'not authenticated') {
                    console.log('No current user.');
                } else {
                    ShowErrorNotification('Kullanıcı bilgileri alınamadı', err);
                }
            });
    }

    render() {
        return (
            <Layout className="default-layout">
                <Layout.Header>
                    <Container>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            selectable="false"
                            style={{
                                lineHeight: '63px',
                                backgroundColor: '#364d79',
                                borderBottomWidth: '0px!important'
                            }}
                        >
                            <Menu.Item>
                                <Link to="/" onClick={e => e.stopPropagation()}>
                                    <img style={{height: 48}} src={require('../assets/images/logo_header.png')} alt="logo"/>
                                </Link>
                            </Menu.Item>
                            {!this.state.user && this.props.match.path !== '/login' && (
                                <Menu.Item style={{float: 'right'}}>
                                    <Link to="/login">
                                        <div style={{color: 'white'}}>
                                            <Icon type="login"/>
                                            Kullanıcı Girişi
                                        </div>
                                    </Link>
                                </Menu.Item>
                            )}
                            {this.state.user && (
                                <Menu.SubMenu
                                    style={{float: 'right', color: 'white'}}
                                    title={(
                                        <div>
                                            {this.state.user.name || this.state.user.email}&nbsp;
                                            <Icon type="down"/>
                                        </div>
                                    )}
                                >
                                    <Menu.Item>
                                        <Link to="/profile">
                                            <Icon type="user"/>
                                            Hesabım
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item onClick={this.logout}>
                                        <Icon type="logout"/>
                                        Oturumu Kapat
                                    </Menu.Item>
                                </Menu.SubMenu>

                            )}
                        </Menu>
                    </Container>
                </Layout.Header>
                <Layout.Content>
                    {this.props.children}
                </Layout.Content>
                {/*<Layout.Footer>*/}
                {/*    <Col align="center">*/}
                {/*        Made with <span role='img'>❤</span>️ by <a href="http://www.asyasoft.net" target="_blank" rel="noopener noreferrer">Asyasoft</a>*/}
                {/*    </Col>*/}
                {/*</Layout.Footer>*/}
            </Layout>
        );
    }
}

export default withRouter(DefaultLayout);
