import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Button, Col, Form, Input, notification, Row} from 'antd';
import {DefaultLayout} from "../../layouts";
import {Container} from '../../components';
import {Authentication, ShowErrorNotification, ShowValidationErrorNotification} from '../../utils';
import QueryString from 'query-string';

class LoginPage extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderLoginForm = this.renderLoginForm.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            const {email, password} = values;
            this.setState({loggingIn: true});
            Authentication.login(email, password)
                .then(user => {
                    console.log("User logged in.", user);
                    notification.success({
                        message: "Kullanıcı girişi yapıldı",
                        description: "Güvenliğiniz için çıkış yapmayı unutmayın."
                    });
                    const {history, location} = this.props;
                    const {returnUrl} = QueryString.parse(location.search);
                    setTimeout(() => {
                        if (returnUrl !== undefined) {
                            console.log("Returning to url.", returnUrl);
                            history.push(returnUrl);
                        } else {
                            history.push("/");
                        }
                    }, 100);
                })
                .catch(err => {
                    if (err.message === 'Kullanıcı hesabı etkinleştirilmemiş.') {
                        this.props.history.push(`/confirm?email=${email}`);
                    }
                    ShowErrorNotification('Kullanıcı girişi yapılamadı', err);
                })
                .finally(() => this.setState({loggingIn: false}));
        });
    }

    renderLoginForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item label='Email Adresi'>
                            {getFieldDecorator('email', {
                                rules: [
                                    { required: true, message: 'Email adresi girilmemiş' },
                                    { type: 'email', message: 'Geçerli bir email adresi değil' }
                                ],
                            })(<Input/>)}
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label='Şifre'>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Şifre girilmemiş' }],
                            })(<Input.Password/>)}
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    icon="login"
                    loading={this.state.loggingIn}
                >
                    Giriş
                </Button>
                <div style={{textAlign: 'right'}}>
                    <Link className="login-form-forgot" to='/forgot-password'>Şifremi unuttum!</Link>
                </div>
            </Form>
        );
    }

    componentDidMount() {
        Authentication.getCurrentSession()
            .then(user => {
                if (user) this.props.history.push('/')
            });
    }

    render() {
        return (
            <DefaultLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">Kullanıcı Girişi</h1>
                        <Row gutter={24}>
                            <Col md={12}>
                                <div className="sidebar">
                                    <h3>Kayıt giriş ekranına hoş geldiniz.</h3>
                                    <h3>Bu ekranda iki yol izleyebilirsiniz.</h3>
                                    <p><strong>1. ‘Yeni Hesap Aç’</strong> butonuna tıklayarak eposta ve öğrencinin bilgilerini sisteme kaydedebilirsiniz. Şifre oluşturarak bilgilerinize güvence altına alırsınız.</p>
                                    <p>Bu yolu kullandığınız takdirde, bilgiler 3. Kişilere kesinlikle iletilmeyecektir. Sadece Türkçe ve Türk Kültürü dersine başvurunuz için kullanılacaktır.</p>
                                    <p><strong>2. ‘Hesap Açmadan Devam Et.’</strong> Butonuna tıkladığınızda email ve öğrenci bilgilerini girerek başvuru formunu oluşturabilirsiniz.</p>
                                    <p>Bu yolu kullandığınız takdirde bilgileriniz sistemde kaydedilmeyecektir. Sadece kayıt tarihindeki eğitim-öğretim yılı için başvuru formunu hazırlamış olacaksınız.</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <br/>
                                <h1>Kayıtlı Kullanıcı</h1>
                                {this.renderLoginForm()}
                                <br/>
                                <br/>
                                <h1>Yeni Kullanıcı</h1>
                                <Row type="flex" gutter={[16,16]}>
                                    <Col>
                                        <Link to='/signup'>
                                            <Button>Yeni Hesap Aç</Button>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link to='/guest/register'>
                                            <Button type="dashed">Hesap Açmadan Devam Et</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default Form.create()(withRouter(LoginPage));
