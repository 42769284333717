import config from "../../config";
import Amplify, {Auth} from "aws-amplify";
import {CustomError} from "../../utils";

class Authentication {
    constructor() {
        Amplify.configure({
            Auth: {
                region: config.authentication.region,
                userPoolId: config.authentication.poolId,
                userPoolWebClientId: config.authentication.clientId,
                mandatorySignIn: false,
                authenticationFlowType: 'USER_PASSWORD_AUTH'
            },
            Analytics: {
                disabled: true
            }
        });
    }

    signUp(username, password, attributes, validationData = []) {
        let transformedAttributes = undefined;
        if (attributes instanceof Object) {
            transformedAttributes = Object.keys(attributes).reduce((result, current) => {
                const name = StandardAttributes.includes(current) ? current : `custom:${current}`;
                const value = attributes[current];
                if (value) {
                    result[name] = value;
                }
                return result;
            }, {});
        }
        return Auth.signUp({
            username: username?.trim().toLowerCase(),
            password,
            attributes: transformedAttributes,
            validationData
        })
            .then(data => {
                console.log("Signed up.", data);
                return data;
            })
            .catch(err => {
                if (err.code === 'UsernameExistsException') {
                    throw new CustomError('Bu kullanıcı zaten sistemde kayıtlı. Şifrenizi hatırlamıyorsanız "Şifremi Unuttum" seçeneğini kullanarak yeni şifre alabilirsiniz.')
                }
                throw err;
            });
    }

    confirmSignUp(username, code) {
        return Auth.confirmSignUp(username?.trim().toLowerCase(), code)
            .then(data => {
                console.log('Sign up confirmed.', data);
                return data;
            });
    }

    resendSignUpConfirmation(username) {
        return Auth.resendSignUp(username?.trim().toLowerCase())
            .then(data => {
                console.log('Sign up code resent.', data);
                return data;
            });
    }

    login(username, password) {
        return Auth.signIn(username?.trim().toLowerCase(), password)
            .then(user => {
                console.log('User logged in.', user);
                // if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                //     return Auth.completeNewPassword(user, password, null);
                // }
                // return user;
                return {
                    user,
                    challenge: user.challengeName
                }
            })
            .catch(err => {
                switch(err.code) {
                    case 'UserNotConfirmedException':
                        throw new CustomError('Kullanıcı hesabı etkinleştirilmemiş.', err);
                    case 'PasswordResetRequiredException':
                        throw new CustomError('Şifre değişikliği gerekiyor.', err);
                    case 'NotAuthorizedException':
                    case 'UserNotFoundException':
                        throw new CustomError('Hatalı kullanıcı adı veya şifre.', err);
                    default:
                        throw new CustomError(undefined, err);
                }
            });
    }

    completeNewPassword(user, newPassword, attributes) {
        return Auth.completeNewPassword(user, newPassword, attributes)
            .then(user => {
                console.log('New password completed.', user);
                return user;
            });
    }

    logout(global = false) {
        return Auth.signOut({global})
            .then(data => {
                console.log('User logged out.', data);
                return data;
            });
    }

    changePassword(oldPassword, newPassword) {
        return Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                console.log('Password changed.', data);
                return data;
            });
    }

    forgotPassword(username) {
        return Auth.forgotPassword(username?.trim().toLowerCase())
            .then(data => {
                console.log('Forgot password sent.', data);
                return data;
            })
            .catch(err => {
                if (err.code === 'LimitExceededException') {
                    throw new CustomError('Çok fazla deneme yapılmış. Bir süre sonra tekrar deneyebilirsiniz.');
                }
                throw err;
            });
    }

    resetPassword(username, code, newPassword) {
        return Auth.forgotPasswordSubmit(username?.trim().toLowerCase(), code, newPassword)
            .then(data => {
                console.log('Password reset.', data);
                return data;
            })
            .catch(err => {
                if (err.code === 'CodeMismatchException') {
                    throw new CustomError('Girilen kod hatalı veya süresi dolmuş.', err)
                } else if (err.code === 'InvalidPasswordException') {
                    throw new CustomError('Şifre yeterince karmaşık değil.', err)
                }
                throw err;
            });
    }

    updateProfile(attributes) {
        let transformedAttributes = undefined;
        if (attributes instanceof Object) {
            transformedAttributes = Object.keys(attributes).reduce((result, current) => {
                result[StandardAttributes.includes(current) ? current : `custom:${current}`] = attributes[current];
                return result;
            }, {});
        }
        return Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.updateUserAttributes(user, transformedAttributes);
            });
    }

    getCurrentUser(bypassCache = false) {
        return Auth.currentAuthenticatedUser({bypassCache})
            .then(user => {
                console.log('Current user', user);
                if (user) {
                    const {sub: id, username, attributes} = user;
                    return {id, username, ...attributes};
                }
                return user;
            })
            .catch(err => {
                if (err === 'not authenticated') {
                    console.log("Not authenticated");
                    return undefined;
                }
                throw err;
            });
    }

    getCurrentSession() {
        return Auth.currentSession()
            .then(session => {
                console.log('Current session', session);
                return session;
            });
    }
}

const StandardAttributes = [
    'address',
    'birthdate',
    'email',
    'family_name',
    'gender',
    'given_name',
    'locale',
    'middle_name',
    'name',
    'phone_number',
    'picture',
    'preferred_username',
    'profile',
    'zoneinfo',
    'updated_at',
    'website'
];

export default new Authentication();
