import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Col, Form, Input, notification, Row} from 'antd';
import {DefaultLayout} from "../../layouts";
import {Container} from "../../components";
import {Authentication, ShowValidationErrorNotification} from '../../utils';
import QueryString from 'query-string';
import ShowErrorNotification from "../../utils/error/ShowErrorNotification";

class SignUpConfirmPage extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resend = this.resend.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification(err);
            }
            const {email, code} = values;
            this.setState({submitting: true});
            Authentication.confirmSignUp(email, code)
                .then(success => {
                    notification.success({
                        message: 'Hesabınız etkinleştirildi',
                        description: 'Şimdi kullanıcı adı ve şifrenizle giriş yapabilirsiniz.'
                    });
                    this.props.history.push('/login');
                })
                .catch(err => {
                    console.log('Error signing up.', err);
                    notification.success({
                        message: 'Kullanıcı kaydı gönderilemedi',
                        description: 'Lütfen daha sonra tekrar deneyin.'
                    });
                })
                .finally(() => this.setState({submitting: false}));
        });
    };

    resend() {
        this.props.form.validateFieldsAndScroll(['email'], (err, values) => {
            if (err) {
                return ShowValidationErrorNotification(err);
            }
            const {email} = values;
            this.setState({resending: true});
            Authentication.resendSignUpConfirmation(email)
                .then(success => {
                    notification.success({
                        message: 'Email tekrar gönderildi',
                        description: 'Emailinizi kontrol edip yönergelere göre onay sürecini tamamlayın.'
                    });
                })
                .catch(err => ShowErrorNotification('Email tekrar gönderilemedi', err))
                .finally(() => this.setState({resending: false}));
        });
    }

    render() {
        const {email, code} = QueryString.parse(this.props.location.search);
        const {getFieldDecorator} = this.props.form;
        return (
            <DefaultLayout>
                <Container size="small">
                    <div className="page">
                        <h1 className="page-header">Hesabımı Etkinleştir</h1>
                        <p>Kaydınız alındıktan sonra e-posta adresinize bir onay kodu gönderdik. Lütfen o kodu aşağıya girip hesabınızı etkinleştirin.</p>
                        <p>Kodunuz gelmediyse veya süresi geçmişse yeni kod göndermemiz için aşağıdaki "Yeniden Gönder" tuşuna tıklayarak yeni kod alabilirsiniz.</p>
                        <Form onSubmit={this.handleSubmit}>
                            <Row gutter={24}>
                                <Col>
                                    <Form.Item label="Email Adresi">
                                        {getFieldDecorator('email', {
                                            initialValue: email,
                                            rules: [
                                                {required: true, message: 'Email adresi girilmemiş'},
                                                {type: 'email', message: 'Geçerli bir email adresi değil'}
                                            ]
                                        })(<Input disabled={email !== undefined} />)}
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Kod">
                                        {getFieldDecorator('code', {
                                            initialValue: code,
                                            rules: [{required: true, message: 'Kod girilmemiş'}]
                                        })(<Input disabled={code !== undefined} autoFocus={code === undefined}/>)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br/>
                            <Row type="flex" gutter={[16,16]}>
                                <Col>
                                    <Button
                                        loading={this.state.submitting}
                                        type="primary"
                                        htmlType="submit"
                                        icon="check"
                                    >
                                        Etkinleştir
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        loading={this.state.resending}
                                        icon="mail"
                                        onClick={this.resend}
                                    >
                                        Yeniden Gönder
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default Form.create()(withRouter(SignUpConfirmPage));
