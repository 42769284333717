import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Col, Form, Input, notification, Row} from 'antd';
import {DefaultLayout} from "../../layouts";
import {Container} from '../../components';
import {Authentication, ShowErrorNotification, ShowValidationErrorNotification} from '../../utils';

class SignUpPage extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
        this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
        this.validateToNextPassword = this.validateToNextPassword.bind(this);
        // this.validateAgreement = this.validateAgreement.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification(err);
            }
            // if (!values.agreement) {
            //     return notification.error({
            //         message: 'Kullanıcı sözleşmesi kabul edilmemiş',
            //         description: 'Lütfen kullanıcı sözleşmesini okuyup onayladın.'
            //     });
            // }
            let {email, password, confirm, ...attributes} = values;
            if (attributes.phone_number?.length) {
                attributes.phone_number = `+49${attributes.phone_number}`;
            }
            this.setState({submitting: true});
            Authentication.signUp(email, password, attributes)
                .then(() => {
                    notification.success({
                        message: 'Kullanıcı kaydı gönderildi',
                        description: 'Emailinizi kontrol edip yönergelere göre onay sürecini tamamlayın.'
                    });
                    setTimeout(() => {
                        this.props.history.push(`/confirm?email=${email}`)
                    }, 100);
                })
                .catch(err => ShowErrorNotification('Kullanıcı kaydı gönderilemedi', err))
                .finally(() => this.setState({submitting: false}));
        });
    };

    handleConfirmBlur(e) {
        this.setState({confirmDirty: this.state.confirmDirty || !!e.target.value});
    };

    compareToFirstPassword(rule, value, callback) {
        if (value && value !== this.props.form.getFieldValue('password')) {
            callback('Girilen şifreler aynı değil');
        } else {
            callback();
        }
    };

    validatePhoneNumber(rule, value, callback) {
        if (value && !value.match(/[\d]{10}$/)) {
            callback('Geçersiz telefon numarası');
        } else {
            callback();
        }
    };

    validateToNextPassword(rule, value, callback) {
        if (value && this.state.confirmDirty) {
            this.props.form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    // validateAgreement(rule, value, callback) {
    //     if (value !== true) {
    //         callback('Kullanıcı sözleşmesi kabul edilmemiş');
    //     } else {
    //         callback();
    //     }
    // };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <DefaultLayout>
                <Container size="small">
                    <div className="page">
                        <h1 className="page-header">Veli Kullanıcı Kaydı</h1>
                        <Form onSubmit={this.handleSubmit}>
                            <Row gutter={24}>
                                <Col>
                                    <Form.Item label="Ad, Soyad">
                                        {getFieldDecorator('name', {
                                            rules: [{required: true, message: 'Ad, soyad girilmemiş'}]
                                        })(<Input autoFocus/>)}
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Email Adresi">
                                        {getFieldDecorator('email', {
                                            rules: [
                                                {required: true, message: 'Email adresi girilmemiş'},
                                                {type: 'email', message: 'Geçerli bir email adresi değil'}
                                            ]
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Telefon Numarası">
                                        {getFieldDecorator('phone_number', {
                                            rules: [{validator: this.validatePhoneNumber}]
                                        })(<Input addonBefore="+49" />)}
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Şifre">
                                        {getFieldDecorator('password', {
                                            rules: [
                                                {required: true, message: 'Şifre girilmemiş'},
                                                {min: 8, message: 'Şifre en az 8 karakter olmalı'},
                                                {validator: this.validateToNextPassword}
                                            ],
                                        })(<Input.Password />)}
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Şifre (tekrar)">
                                        {getFieldDecorator('confirm', {
                                            rules: [
                                                {required: true, message: 'Şifre tekrar girilmemiş'},
                                                {validator: this.compareToFirstPassword}
                                            ],
                                        })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/*<Form.Item>*/}
                            {/*    {getFieldDecorator('agreement', {*/}
                            {/*        valuePropName: 'checked',*/}
                            {/*        rules: [{validator: this.validateAgreement}]*/}
                            {/*    })(*/}
                            {/*        <Checkbox>*/}
                            {/*            <a href="https://www.termsfeed.com/blog/wp-content/uploads/2019/04/eula-template.pdf" target="_blank" rel="noopener noreferrer">Kullanıcı sözleşmesini</a> okudum ve kabul ediyorum.*/}
                            {/*        </Checkbox>*/}
                            {/*    )}*/}
                            {/*</Form.Item>*/}
                            <br/>
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon="check"
                                loading={this.state.submitting}
                            >
                                Kayıt ol
                            </Button>
                        </Form>
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default Form.create()(withRouter(SignUpPage));
