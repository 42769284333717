export default {
    api: {
        url: "https://2uihjsdiuh.execute-api.eu-central-1.amazonaws.com/production"
    },
    authentication: {
        region: 'eu-central-1',
        poolId: 'eu-central-1_qZ6K9B3b0',
        clientId: '41duk2iu1q7t536iko4c195q6'
    }
};
