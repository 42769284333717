import React, {Component} from 'react';
import {Button, Modal, Result} from "antd";

class RegistrationSuccessModal extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    loadData() {
        const {visible} = this.props;
        if (this.state.visible !== visible) {
            this.setState({visible});
        }
    }

    closeModal() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible) {
            this.loadData();
        }
    }

    render() {
        return (
            <Modal
                {...this.props}
                title="Çocuğum neden Türkçe öğrenmeli?"
                visible={this.state.visible}
                onOk={this.closeModal}
                onCancel={this.closeModal}
                footer={null}
            >
                <Result
                    status="success"
                    title="Öğrenci Ders Kaydınız Alındı!"
                    subTitle="Lütfen başvuru formunuzu indirip yazıcıdan çıktı aldıktan sonra imzalayarak ilgili kuruma iletin."
                    extra={[
                        <a
                            key="download"
                            href={this.props.registration?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button
                                type="primary"
                                icon="file-pdf"
                                loading={!this.props.registration}
                            >
                                PDF Dosyasını İndir
                            </Button>
                        </a>,
                        <Button key="back" onClick={this.closeModal}>
                            Yeni Form Doldur
                        </Button>
                    ]}
                />
            </Modal>
        );
    }
}

export default RegistrationSuccessModal;
