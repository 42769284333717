import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Alert, Button, Col, Form, Icon, Input, notification, Row, Table} from 'antd';
import {DefaultLayout} from "../../layouts";
import {Container, RegistrationDetailsModal} from "../../components";
import {Authentication, ShowErrorNotification, ShowValidationErrorNotification} from "../../utils";
import data from '../../data';
import moment from 'moment';

class ProfilePage extends Component {
    state = {
        loading: true
    };

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
        this.openViewModal = this.openViewModal.bind(this);
        this.closeViewModal = this.closeViewModal.bind(this);
        this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
        this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
        this.validateToNextPassword = this.validateToNextPassword.bind(this);
        this.renderUpdateForm = this.renderUpdateForm.bind(this);
        this.renderTables = this.renderTables.bind(this);
    }

    loadData() {
        this.setState({loading: true});
        Promise.all([
            Authentication.getCurrentUser(),
            data.allRegistrations()
        ])
            .then(([user, registrations]) => {
                if (user?.phone_number?.startsWith("+49")) {
                    user.phone_number = user.phone_number.substring(3);
                }
                this.setState({user, registrations});
            })
            .catch(err => ShowErrorNotification('Öğrenci ders kayıtları alınamadı', err))
            .finally(() => this.setState({loading: false}));
    }

    handleUpdateProfile(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({submitting: true});
            let {password, newPassword, newPasswordConfirm, ...attributes} = values;
            if (attributes.phone_number) {
                attributes.phone_number = `+49${attributes.phone_number}`;
            }
            const operations = [Authentication.updateProfile(attributes)];
            if (newPassword) {
                operations.push(Authentication.changePassword(password, newPassword));
            }
            Promise.all(operations)
                .then(async () => {
                    notification.success({
                        message: 'Profil bilgileriniz güncellendi',
                        description: 'Bu sayfa şimdi tekrar yüklenilecek. Lütfen bekleyin.'
                    });
                    await Authentication.getCurrentUser(true);
                    window.location.reload();
                })
                .catch(err => ShowErrorNotification('Profil bilgileriniz güncellenemedi', err))
                .finally(() => this.setState({submitting: false}));
        });
    }

    openViewModal(record) {
        this.setState({modalVisible: true, record});
    }

    closeViewModal() {
        this.setState({modalVisible: false});
    }

    handleConfirmBlur(e) {
        this.setState({confirmDirty: this.state.confirmDirty || !!e.target.value});
    };

    compareToFirstPassword(rule, value, callback) {
        if (value && value !== this.props.form.getFieldValue('newPassword')) {
            callback('Girilen şifreler aynı değil');
        } else {
            callback();
        }
    };

    validatePhoneNumber(rule, value, callback) {
        if (value && !value.match(/[\d]{10}$/)) {
            callback('Geçersiz telefon numarası');
        } else {
            callback();
        }
    };

    validateToNextPassword(rule, value, callback) {
        if (value && this.state.confirmDirty) {
            this.props.form.validateFields(['newPasswordConfirm'], { force: true });
        }
        callback();
    };

    renderUpdateForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleUpdateProfile}>
                <Row gutter={24}>
                    <Col sm={12} md={8}>
                        <Form.Item label="Ad, Soyad">
                            {getFieldDecorator('name', {
                                initialValue: this.state.user?.name,
                                rules: [{required: true, message: 'Ad, soyad girilmemiş'}]
                            })(<Input autoFocus/>)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Email Adresi">
                            <Input disabled value={this.state.user?.email}/>
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Telefon Numarası">
                            {getFieldDecorator('phone_number', {
                                initialValue: this.state.user?.phone_number || '',
                                rules: [{validator: this.validatePhoneNumber}]
                            })(<Input addonBefore="+49" />)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Şifre">
                            {getFieldDecorator('password')(<Input.Password />)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Yeni Şifre">
                            {getFieldDecorator('newPassword', {
                                rules: [
                                    {min: 8, message: 'Şifre en az 8 karakter olmalı'},
                                    {validator: this.validateToNextPassword}
                                ],
                            })(<Input.Password />)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Yeni Şifre (tekrar)">
                            {getFieldDecorator('newPasswordConfirm', {
                                rules: [
                                    {validator: this.compareToFirstPassword}
                                ],
                            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <br/>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon="check"
                    loading={this.state.submitting}
                >
                    Kaydet
                </Button>
            </Form>
        );
    }

    renderTables() {
        if (!this.state.registrations?.length) {
            return (
                <Alert
                    message="Daha önce doldurduğunuz form bulunamadı."
                    type="error"
                />
            )
        }
        return (
            <Table
                rowKey="id"
                loading={this.state.loading}
                columns={columns}
                dataSource={this.state.registrations.sort((a, b) => {
                    if(a.created_at < b.created_at) { return 1; }
                    if(a.created_at > b.created_at) { return -1; }
                    return 0;
                })}
                pagination={false}
                onRow={record => ({
                    style: {cursor: 'pointer'},
                    onClick: () => this.openViewModal(record)
                })}
            />
        )
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <DefaultLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">
                            Hesabım
                        </h1>
                        <br/>
                        {this.state.loading ? (
                            <React.Fragment>
                                <Icon spin type="sync" style={{marginRight: 5}}/>
                                Lütfen bekleyin...
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {this.renderUpdateForm()}
                                <br/>
                                <br/>
                                <h1 className="page-header">
                                    Öğrenci Ders Kayıt Formlarım
                                </h1>
                                {this.renderTables()}
                                <div style={{marginTop: 16, marginBottom: 8}}>
                                    <Link to="/register"><Button icon="plus">Yeni Form Doldur</Button></Link>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <RegistrationDetailsModal
                        visible={this.state.modalVisible}
                        record={this.state.record}
                        onClose={this.closeViewModal}
                    />
                </Container>
            </DefaultLayout>
        );
    }
}

const columns = [{
    title: 'Tarih',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, record) => <span>{moment(record.created_at).format('DD/MM/YYYY')}</span>
}, {
    title: 'Öğrenci Adı, Soyadı',
    dataIndex: 'student_name',
    key: 'student_name'
}, {
    title: 'Okul Adı',
    dataIndex: 'school_name',
    key: 'school_name'
}, {
    title: 'Sınıf',
    dataIndex: 'class_name',
    key: 'class_name'
}];

export default Form.create()(withRouter(ProfilePage));
