import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import * as Pages from './pages';
import {ProtectedRoute} from './utils';
import './App.less';

class App extends Component {
  render() {
    return (
      <div className="App">
          {/*<div style={{*/}
          {/*    color: 'white',*/}
          {/*    backgroundColor: 'red',*/}
          {/*    padding: 8,*/}
          {/*    textAlign: 'center',*/}
          {/*}}>*/}
          {/*    Bu uygulama test aşamasındadır. Girdiğiniz veriler canlıya geçmeden önce silinecektir.*/}
          {/*</div>*/}
          <Router>
              <Switch>
                  <Route exact path="/" component={Pages.HomePage}/>
                  <ProtectedRoute exact path="/profile" component={Pages.ProfilePage}/>
                  <Route exact path="/plans" component={Pages.PlanListPage}/>
                  <ProtectedRoute exact path="/register" component={Pages.RegistrationPage}/>
                  <Route exact path="/guest/register" component={Pages.RegistrationPage}/>
                  <Route exact path="/login" component={Pages.LoginPage}/>
                  <Route path="/signup" component={Pages.SignUpPage}/>
                  <Route path="/confirm" component={Pages.SignUpConfirmPage}/>
                  <Route exact path="/forgot-password" component={Pages.ForgotPasswordPage}/>
                  <Route exact path="/reset-password" component={Pages.ResetPasswordPage}/>
                  <Pages.NotFoundPage/>
              </Switch>
          </Router>
      </div>
    );
  }
}

export default App;
