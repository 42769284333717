import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Button, Col, Modal, Row} from "antd";

class PlanDetailsModal extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    loadData() {
        this.setState({
            record: this.props.record,
            visible: this.props.visible
        });
    }

    closeModal() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible) {
            this.loadData();
        }
    }

    render() {
        return (
            <Modal
                {...this.props}
                title="Öğrenci Ders Kayıt Formu"
                visible={this.state.visible}
                onOk={this.closeModal}
                onCancel={this.closeModal}
                footer={[
                    <Link
                        key="copy"
                        to={{
                            pathname: '/register',
                            state: {template: this.state.record}
                        }}
                        style={{marginRight: 8}}
                    >
                        <Button icon="copy">Kopyala</Button>
                    </Link>,
                    <Button
                        key="close"
                        onClick={this.closeModal}>
                        Kapat
                    </Button>
                ]}
                destroyOnClose={true}
            >
                <Col type="flex">
                    <Row type="flex" justify="center">
                        <p style={{marginTop: 32, marginBottom: 32, textAlign: 'center'}}>Formu imzaladıktan sonra okul sekretaryasına teslim edebilirsiniz.</p>
                    </Row>
                    <Row type="flex" justify="center">
                        <a
                            href={this.props.record?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button
                                type="primary"
                                size="large"
                                icon="file-pdf"
                            >
                                PDF Dosyasını İndir
                            </Button>
                        </a>
                    </Row>
                    <br/>
                </Col>
            </Modal>
        );
    }
}



export default withRouter(PlanDetailsModal);
