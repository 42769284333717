import React, {Component} from 'react';
import {Alert, Icon, notification} from 'antd';
import {DefaultLayout} from "../../layouts";
import data from '../../data';
import {Container} from "../../components";

class PlanListPage extends Component {
    state = {
        loading: true
    };

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.openViewModal = this.openViewModal.bind(this);
        this.closeViewModal = this.closeViewModal.bind(this);
        this.renderTables = this.renderTables.bind(this);
    }

    loadData() {
        this.setState({loading: true});
        Promise.all([
            data.allPeriods(),
            data.allPlans()
        ])
            .then(([periods, plans]) => this.setState({periods, plans}))
            .catch(err => {
                console.log('Error getting periods and plans.', err);
                notification.error({
                    message: 'Eğitim-öğretim yılları ve ders planları alınamadı',
                    description: 'Lütfen daha sonra tekrar deneyin.'
                });
            })
            .finally(() => this.setState({loading: false}));
    }

    openViewModal(record) {
        this.setState({modalVisible: true, record});
    }

    closeViewModal() {
        this.setState({modalVisible: false});
    }

    renderTables() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <Icon spin type="sync" style={{marginRight: 5}}/>
                    Lütfen bekleyin...
                </React.Fragment>
            )
        }
        if (!this.state.periods?.length) {
            return (
                <Alert
                    message="Ders Planı Bulunamadı"
                    description="Şu anda aktif ders planı bulunmamaktadır. Lütfen daha sonra sitemizi tekrar ziyaret edin."
                    type="error"
                />
            )
        }
        return (
            <React.Fragment>
                {this.state.periods
                    .sort((a, b) => {
                        if(a.name < b.name) { return 1; }
                        if(a.name > b.name) { return -1; }
                        return 0;
                    })
                    .map(period => {
                        const plans = this.state.plans
                            .filter(plan => plan.period_id === period.id)
                            .sort((a, b) => {
                                if(a.name < b.name) { return 1; }
                                if(a.name > b.name) { return -1; }
                                return 0;
                            });
                        return (
                            <div key={period.id}>
                                <h3>{period.name}</h3>
                                <br/>
                                {plans.length ? plans.map(plan => (
                                    <p key={plan.id}>
                                        <a
                                            href={plan.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Icon type="file-text" />&nbsp;&nbsp;{plan.name_tr} ({plan.name_de})
                                        </a>
                                    </p>
                                )) : (
                                    <Alert
                                        message="Bu eğitim öğretim dönemi için plan bulunamadı."
                                        type="error"
                                    />
                                )}
                                <br/>
                                <br/>
                            </div>
                        );
                    })}
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <DefaultLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">
                            Ders Planları
                        </h1>
                        <br/>
                        {this.renderTables()}
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default PlanListPage;
