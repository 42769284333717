import {GenericAPI} from "./generic-api-client";
import {API} from "../../utils/api";

export default GenericAPI("public/registrations", () => {
    return {
        createWithUser(data, params) {
            return API.post('/registrations', {...data, params});
        },
        all(params) {
            return API.all(`registrations`, {params});
        },
        list(params) {
            return API.list('/registrations', {params});
        },
        get(id, params) {
            return API.get(`/registrations/${id}`, {params});
        }
    }
});
