import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Col, Form, Icon, Input, notification, Radio, Row, Select} from 'antd';
import {DefaultLayout} from "../../layouts";
import {Container, RegistrationSuccessModal} from '../../components';
import {Authentication, ShowErrorNotification, ShowValidationErrorNotification} from "../../utils";
import data from '../../data';
import YouTube from "react-youtube";

class RegistrationPage extends Component {

    state = {
        submitting: false
    };

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        this.openSuccessModal = this.openSuccessModal.bind(this);
        this.closeSuccessModal = this.closeSuccessModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
        this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
        this.validateToNextPassword = this.validateToNextPassword.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    loadData() {
        this.setState({
            template: this.props.location.state?.template,
            loading: true
        });
        Promise.all([
            Authentication.getCurrentUser(),
            data.allPeriods({enabled: true}),
            data.allSchools({enabled: true}),
            data.allSettings()
        ])
            .then(([user, periods, schools, settings]) => {
                const transformedSettings = settings.reduce((result, current) => {
                    result[current.key] = current.value;
                    return result;
                }, {});
                const currentPeriod = periods?.find(p => p.id === transformedSettings.CURRENT_PERIOD);
                this.setState({
                    user,
                    periods,
                    schools,
                    currentPeriod,
                    settings: transformedSettings
                });
            })
            .catch(err => ShowErrorNotification('Okullar alınamamadı', err))
            .finally(() => this.setState({loading: false}));
    }

    handlePeriodChange(periodId) {
        const currentPeriod = this.state?.periods?.find(p => p.id === periodId);
        this.setState({currentPeriod});
    }

    openSuccessModal(registration) {
        this.setState({registration, submitted: true});
    }

    closeSuccessModal() {
        this.setState({registration: undefined, submitted: false}, () => {
            this.props.form.resetFields();
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({submitting: true});
            const createRegistration = this.state.user
                ? data.createRegistrationWithUser(values)
                : data.createRegistration(values);
            createRegistration
                .then(registration => {
                    console.log("registration", registration);
                    notification.success({
                        message: "Öğrenci Ders Kaydınız Alındı!",
                        description: "Lütfen başvuru formunuzu indirip yazıcıdan çıktı aldıktan sonra imzalayarak ilgili kuruma iletin."
                    });
                    this.setState({registration, submitted: true});
                })
                .catch(err => ShowErrorNotification('Öğrenci ders kaydı yapılamadı', err))
                .finally(() => this.setState({submitting: false}));
        });
    }

    handleConfirmBlur(e) {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword(rule, value, callback) {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent');
        } else {
            callback();
        }
    };

    validateToNextPassword(rule, value, callback) {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    renderForm() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <Icon spin type="sync" style={{marginRight: 5}}/>
                    Lütfen bekleyin...
                </React.Fragment>
            );
        }
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                <h3 className="form-group-label">Veli Bilgileri (Eltern)</h3>
                <Row gutter={24}>
                    <Col sm={12} md={8}>
                        <Form.Item label="Ad, Soyad (Vorname, Name)">
                            {getFieldDecorator('parent_name', {
                                initialValue: this.state.user?.name,
                                rules: [{required: true, message: 'Veli adı, soyadı girilmemiş'}]
                            })(<Input autoFocus />)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Telefon">
                            {getFieldDecorator('phone_number', {
                                initialValue: this.state.user?.phone_number,
                                rules: [{required: true, message: 'Telefon numarası girilmemiş'}]
                            })(<Input />)}
                        </Form.Item>
                    </Col>
                    {/*<Col sm={12} md={8}>*/}
                    {/*    <Form.Item label="Email">*/}
                    {/*        {getFieldDecorator('email', {*/}
                    {/*            initialValue: this.state.user?.email,*/}
                    {/*            rules: [{type: 'email', message: 'Email adresi geçerli değil'}]*/}
                    {/*        })(<Input />)}*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col sm={12} md={8}>*/}
                    {/*    <Form.Item label="Yer (Ort)">*/}
                    {/*        {getFieldDecorator('city_name', {*/}
                    {/*            rules: [{required: true, message: 'Şehir adı girilmemiş'}]*/}
                    {/*        })(<Input />)}*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col sm={12} md={8}>*/}
                    {/*    <Form.Item label="Posta Kodu (Postleitzahl)">*/}
                    {/*        {getFieldDecorator('zipcode', {*/}
                    {/*            rules: [{required: true, message: 'Posta kodu girilmemiş'}]*/}
                    {/*        })(<Input />)}*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col sm={12} md={8}>*/}
                    {/*    <Form.Item label="Sokak (Straße)">*/}
                    {/*        {getFieldDecorator('street_name', {*/}
                    {/*            rules: [{required: true, message: 'Sokak adı girilmemiş'}]*/}
                    {/*        })(<Input />)}*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>

                <h3 className="form-group-label">Öğrenci Bilgileri (Schüler/in)</h3>
                <Row gutter={24}>
                    <Col sm={12} md={8}>
                        <Form.Item label="Ad, Soyad (Vorname, Name)">
                            {getFieldDecorator('student_name', {
                                initialValue: this.state.template?.student_name,
                                rules: [{required: true, message: 'Öğrenci adı, soyadı girilmemiş'}]
                            })(<Input />)}
                        </Form.Item>
                    </Col>
                    {/*<Col sm={12} md={8}>*/}
                    {/*    <Form.Item label="Doğum Tarihi (Geburtstag)">*/}
                    {/*        {getFieldDecorator('student_dob', {*/}
                    {/*            rules: [{required: true, message: 'Öğrenci doğum tarihi girilmemiş'}]*/}
                    {/*        })(<DatePicker style={{display: 'block'}} allowClear={false} locale={GermanLocale} format="DD.MM.YYYY" />)}*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col sm={24} md={16}>
                        <Form.Item label="Okul (Schule)">
                            {getFieldDecorator('school_id', {
                                initialValue: this.state.template?.school_id,
                                rules: [{required: true, message: 'Okul seçilmemiş'}]
                            })(
                                <Select
                                    style={{width: '100%'}}
                                    showSearch
                                    disabled={!this.state.schools}
                                    loading={!this.state.schools}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.schools?.map(school => (
                                        <Select.Option
                                            key={school.id}
                                            value={school.id}
                                        >
                                            {school.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Yıl (Schuljahr)">
                            {getFieldDecorator('period_id', {
                                initialValue: this.state.settings?.CURRENT_PERIOD,
                                rules: [{required: true, message: 'Yıl girilmemiş'}],
                                onChange: this.handlePeriodChange
                            })(
                                <Select
                                    style={{width: '100%'}}
                                    disabled={!this.state.periods}
                                    loading={!this.state.periods}
                                >
                                    {this.state.periods?.map(period => (
                                        <Select.Option key={period.id} value={period.id}>{period.name}</Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Sınıf (Klasse)">
                            {getFieldDecorator('class_name', {
                                initialValue: this.state.template?.class_name,
                                rules: [{required: true, message: 'Sınıf girilmemiş'}]
                            })(<Input />)}
                        </Form.Item>
                    </Col>
                </Row>
                <h3 className="form-group-label">&nbsp;</h3>
                <p>Öğrencinin karnesinde {this.state?.currentPeriod?.name || '2019-2020'} Eğitim-Öğretim yılından itibaren Türkçe ve Türk Kültürü dersinin yer
                    almasını istiyor musunuz? (Möchten Sie, dass die Teilnahme am türkischen muttersprachlichen Zusatzunterricht ab dem Schuljahr
                    {this.state?.currentPeriod?.name || '2019-2020'} auf dem Zeugnis Ihres Kindes vermerkt wird?)</p>
                <Form.Item>
                    {getFieldDecorator('checked', {
                        initialValue: this.state.template?.checked !== false,
                        rules: [{required: true, message: 'Sınıf girilmemiş'}]
                    })(
                        <Radio.Group>
                            <Radio value={true}>Evet (Ja)</Radio>
                            <Radio value={false}>Hayır (Nein)</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                <br/>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon="check"
                    loading={this.state.submitting}
                >
                    Kaydet (Speichern)
                </Button>
            </Form>
        );
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <DefaultLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">Öğrenci Ders Kaydı (muttersprachlicher Zusatzunterricht)</h1>
                        <br/>
                        {this.state.settings?.REGISTRATION_VIDEO && (
                            <YouTube
                                videoId={this.state.settings?.REGISTRATION_VIDEO}
                                opts={{width: '100%'}}
                            />
                        )}
                        {this.renderForm()}
                    </div>
                    <RegistrationSuccessModal
                        visible={this.state.submitted}
                        registration={this.state.registration}
                        onClose={this.closeSuccessModal}
                    />
                </Container>
            </DefaultLayout>
        );
    }
}

export default Form.create()(withRouter(RegistrationPage));
