import * as API from './api';

class DataProvider {
    /**
     * Periods
     */
    allPeriods(filter) {
        return API.PeriodsService.all(filter);
    }

    /**
     * Plans
     */
    allPlans(filter) {
        return API.PlansService.all(filter);
    }

    /**
     * Registrations
     */
    allRegistrations(filter) {
        return API.RegistrationsService.all(filter);
    }
    createRegistration(data) {
        return API.RegistrationsService.create(data);
    }
    createRegistrationWithUser(data) {
        return API.RegistrationsService.createWithUser(data);
    }

    /**
     * Schools
     */
    allSchools(filter) {
        return API.SchoolsService.all(filter);
    }

    /**
     * Settings
     */
    allSettings(filter) {
        return API.SettingsService.all(filter);
    }
}

export default new DataProvider();
